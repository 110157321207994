body {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat, Helvetica, Arial, Lucida, sans-serif';
  background-color: black;
}
a {
  text-decoration: none !important;
}
.product {
  height: 30rem;
  width: 30rem;
}
@media (max-width: 1024px) {
  .product {
    height: 23rem;
    width: 23rem;
  }
}
