.swiper-button-prev {
  position: absolute;
  z-index: 9;
  padding: 0;
  margin: -28px;
  display: inline-block;
  background-color: rgba(0, 0, 0, 0.7);
  text-align: center;
  width: 50px;
  height: 50px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
#Review-slider .swiper-button-prev,
#Review-slider .swiper-button-next {
  background-color: transparent !important;
}
#Review-slider .swiper-button-prev::after,
#Review-slider .swiper-button-next::after {
  color: #cccccc !important;
  font-weight: 900;
  font-size: 30px !important;
}

.swiper-button-next {
  position: absolute;
  z-index: 9;
  padding: 0;
  margin: -28px;
  display: inline-block;
  background-color: rgba(0, 0, 0, 0.7);
  text-align: center;
  width: 50px;
  height: 50px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-button-prev:after {
  color: #bbb1b1 !important;
  font-size: 30px;
  text-align: center;
}
.swiper-button-next:after {
  color: #bbb1b1 !important;
  font-size: 30px;
  text-align: center;
}
.swiper-button-prev,
.swiper-button-next {
  color: #fff;
}

.swiper-button-prev::after,
.swiper-button-next::after {
  font-size: 20px;
  font-weight: bold;
}
.css-1uweupm {
  background-color: #fff !important;
}

.css-16wblaj-MuiInputBase-input-MuiOutlinedInput-input {
  max-width: 240px !important;
}
